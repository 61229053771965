import React from 'react';
import { Route } from 'react-router-dom';

import AppNavBar from '../components/AppNavBar';
import Copyright from '../components/Copyright';

import "./BootstrapTheme-1.scss";
import './CustomStyle.scss';

const MainLayout = ({children, ...rest}) => {
  return (
    <React.Fragment>
      <AppNavBar title="AgrowLab" />

      {children}

      <footer>
        <Copyright />
      </footer>
    </React.Fragment>
  )
}

const MainLayoutRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
      <MainLayout>
          <Component {...matchProps} />
      </MainLayout>
    )} />
  )
};

export default MainLayoutRoute;