import React from 'react';
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

import { LoadingIcon, CircularStat } from '../components';

import './Assets.scss';

class AssetsContainer extends React.Component {

    render() {
        return (
            <main className="page-container assets-container">
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <LoadingIcon iconName="icon-spin6" />
                        </Col>
                        <Col className="text-center mb-3">
                            <div className="mx-auto my-auto w-50">
                                <CircularStat
                                    percent={100}
                                    value={Math.floor(2100).toFixed(2)}
                                    unit='°C'
                                    isAlert={false}
                                    classIcon='icon-ec'
                                />
                            </div>
                        </Col>
                        <Col className="text-center mb-3">
                            <div className="mx-auto my-auto w-50">
                                <CircularStat
                                    percent={100}
                                    value={Math.floor(9).toFixed(2)}
                                    unit='°C'
                                    isAlert={true}
                                    classIcon='icon-ph'
                                />
                            </div>
                        </Col>
                        <Col className="text-center mb-3">
                            <div className="mx-auto my-auto w-50">
                                <CircularStat
                                    percent={24.5}
                                    value={Math.floor(24.5).toFixed(2)}
                                    unit='°C'
                                    isAlert={false}
                                    classIcon='icon-temperature'
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>
        )
    }
}

export default AssetsContainer;