import React from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Jumbotron,
    Breadcrumb,
    Form
} from 'react-bootstrap';

import { Api } from '../configs/Api';
import { apiHandler, Utils } from '../libs';
import { LoadingIcon, CircularStat } from '../components';

import './FactoryDetails.scss';
import factory_cover from '../assets/images/factory-cover.jpg'
import leaf_png from '../assets/images/leaf.svg'

class FactoryDetailsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            response: {
                status: null,
                data: null
            },
            factory: {},
            formData: {},
            isFormEditing: false
        };

        this.plantIconMap = {
            'Temperature': "icon-temperature",
            'Humidity': "icon-humidity",
            'CO2': "icon-co2",
            'O3': "icon-o3",
            'Intensity': "icon-intensity",
            'Shade': "icon-shade",
        }

        this.onFactoryOpenEdit = this.onFactoryOpenEdit.bind(this);
        this.onFactoryCloseEdit = this.onFactoryCloseEdit.bind(this);
        this.onFactoryInputChange = this.onFactoryInputChange.bind(this);
        this.onFactorySubmit = this.onFactorySubmit.bind(this);
    }

    async componentDidMount() {
        let factory_id = this.props.match.params.factory_id;

        this.getFactories(factory_id);
        setInterval(() => {
            this.getFactories(factory_id);
        }, 5000);
    }

    getFactories(factory_id) {

        /*
        this.setState({
            factory: factory
        }, () => {
            // console.log( this.state );
        });
        */

        apiHandler.get(Api.factories + '/' + factory_id)
        .then((response) => {

            if( !Object.keys(response.data).length ) {
                const errorMessage =  { response:{ status : 0, data : "Empty factory data" } };
                throw errorMessage;
            }

            this.setState({
                response: {
                    status: response.status
                },
                factory: response.data
            }, () => {
                // console.log( this.state );
            });

        }).catch((error) => {

            if( !Object.keys(error).length ) {
                error = { response:{ status : 0, data : "Can't connect server" } };
            }

            this.setState({
                response: {
                    status: error.response.status,
                    data: error.response.data
                }
            }, () => {
                console.error( this.state );
            });

        });
    }

    onFactoryOpenEdit(event) {
        this.setState({
            isFormEditing: true,
            formData: {
                id:this.state.factory.id,
                name:this.state.factory.name,
                description: this.state.factory.description
            }
        });
    }

    onFactoryCloseEdit(event) {
        this.setState({
            isFormEditing: false
        });
    }

    onFactoryInputChange(event) {

        event.preventDefault();
        const { name, value } = event.target;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        }, () => {
                // console.log(this.state);
            }
        );
    }

    onFactorySubmit(event) {

        event.preventDefault();

        try {
            // TODO
            const formData = this.state.formData;

            apiHandler.patch(Api.factories + '/' + formData.id, formData)
            .then((response) => {

                if( !Object.keys(response.data).length ) {
                    const errorMessage =  { response:{ status : 0, data : "Empty response factories data" } };
                    throw errorMessage;
                }

                this.setState({
                    isFormEditing: false,
                    response: {
                        status: response.status
                    },
                    factory: response.data
                }, () => {
                    // console.log( this.state );
                });

            }).catch((error) => {

                if( !Object.keys(error).length ) {
                    error = { response:{ status : 0, data : "Can't connect server" } };
                }

                this.setState({
                    response: {
                        status: error.response.status,
                        data: error.response.data
                    }
                }, () => {
                    console.error( this.state );
                });

            });

        } catch(error) {
            console.error( error );
        }
    }

    render() {

        const response = this.state.response;
        const cFactory = this.state.factory || {};
        const cPlants = cFactory.plants || [];

        let result = Utils.getHtmlOnloading(response);

        let content = null;
        if(result.status !== 'done') {
            content = result.html;
        } else {
            content = (
                <React.Fragment>
                    <Container fluid={true} as="section" className="top-breadcrumb px-0">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href={"/factories"}>
                                Factories
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{cFactory.name}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Container>

                    <Container fluid={true} as="section" className="content">
                        <Row>
                        { !cPlants.length?
                            <Col>
                                <LoadingIcon iconName="icon-spin6" />
                            </Col>
                        :
                            <React.Fragment>
                                {cPlants.map((_plant, _id1) => (
                                <Col key={_id1} xs={12} className="mb-3">
                                    <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col xl={6} className="mb-3">

                                                    <Card.Title><img src={leaf_png} alt={_plant.name} width="5%"/> {_plant.name}</Card.Title>
                                                    <Card.Subtitle className="mb-4 text-muted">{_plant.description}</Card.Subtitle>
                                                    <div className="text-right">
                                                        <Button variant="primary" href={'/plant/' + _plant.id}>
                                                            More Details
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col xl={6}>
                                                    <Row>
                                                    { !_plant.stat?
                                                        <Col>
                                                            <LoadingIcon iconName="icon-spin6" />
                                                        </Col>
                                                    :
                                                        <React.Fragment>
                                                            {_plant.stat.ambient.map((plant_ambient, id2) => (
                                                            <Col key={id2} sm={6} md={4} className="text-center mb-3">
                                                                <div className="mx-auto my-auto w-50">
                                                                    <CircularStat
                                                                        percent={100}
                                                                        value={Math.floor(plant_ambient.value).toFixed(2)}
                                                                        unit={plant_ambient.unit}
                                                                        isAlert={plant_ambient.alert}
                                                                        classIcon={this.plantIconMap[plant_ambient.name]}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            ))}
                                                        </React.Fragment>
                                                    }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                ))}
                            </React.Fragment>
                        }
                        </Row>
                    </Container>
                </React.Fragment>
            );
        }

        return (
            <main className="page-container factory-container">

                <Container fluid={true} as="section" className="header px-0">
                    <Jumbotron fluid={true} className="jumbotron-image">

                    { (this.state.isFormEditing === false)?
                        <React.Fragment>
                            <div className="d-none d-xl-block text-absolute" onClick={ (e)=>{this.onFactoryOpenEdit(e)} }>
                                <figure className="figure">
                                    <h2>{cFactory.name}</h2>
                                    <p>
                                        {cFactory.description}
                                    </p>
                                    <img src={factory_cover} alt={cFactory.name} />
                                </figure>
                            </div>
                            <div className="d-xl-none" onClick={ (e)=>{this.onFactoryOpenEdit(e)} }>
                                <figure className="figure">
                                    <img src={factory_cover} alt={cFactory.name} />
                                    <h1>{cFactory.name}</h1>
                                    <p>
                                        {cFactory.description}
                                    </p>
                                </figure>
                            </div>
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <Form onSubmit={this.onFactorySubmit} className="pt-3">
                                <Form.Group>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="name"
                                        onChange={this.onFactoryInputChange}
                                        defaultValue={cFactory.name}
                                        placeholder="Name"
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="description"
                                        onChange={this.onFactoryInputChange}
                                        defaultValue={cFactory.description}
                                        placeholder="Description"
                                    />
                                </Form.Group>

                                <Form.Group className={ "text-right" }>
                                    <Button variant="primary" type="button" className="mr-1" onClick={ (e)=>{this.onFactoryCloseEdit(e)} }>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type="submit">
                                        Save
                                    </Button>
                                </Form.Group>
                            </Form>
                        </React.Fragment>
                    }

                    </Jumbotron>
                </Container>

                {content}

            </main>
        );
    }
}

export default FactoryDetailsContainer;