import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

import './AppNavBar.scss';

class AppNavBar extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Navbar variant="light" bg="light" expand="lg" fixed="top" className="comp-appnavbar">
          <Navbar.Brand href="/">
            <h1 className="text-hide h1.logo">{this.props.title}</h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="main-navbar-nav" />
          <Navbar.Collapse id="main-navbar-nav" className="justify-content-end">
            <Nav className="mr-auto">
            </Nav>
              <Nav.Link href="/logout"><i className="icon icon-logout-2 xs" />Logout</Nav.Link>
            <Nav>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {/*
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <a className="navbar-brand" href="#">Fixed navbar</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">Link</a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="#">Disabled</a>
              </li>
            </ul>
          </div>
        </nav>
      */}
      </React.Fragment>
    )
  }
}

export default AppNavBar;