import React from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Jumbotron,
    Breadcrumb,
    Form
} from 'react-bootstrap';

import { Api } from '../configs/Api';
import { apiHandler, Utils } from '../libs';
import { LoadingIcon, CircularStat } from '../components';

import './PlantSettings.scss';
import plant_cover from '../assets/images/plant-cover.jpg'

const plantSettings = {
    mode: "MANUAL",
    profileId: 1,
    profileList: [{
        id: 1,
        name: "Kana"
    }, {
        id: 2,
        name: "Salad"
    },{
        id: 3,
        name: "Cat"
    }]
}
class PlantSettingssContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            response: {
                status: null,
                data: null
            },
            plant: {},
            formData: {},
            isFormEditing: false,
            formPlantSettings: {

                mode: plantSettings.mode,
                auto: {
                    profileId: plantSettings.profileId
                },
                manual: {
                    ambient: {
                        temperature: {
                            min:0,
                            max: 0
                        },
                        humidity: {
                            min:0,
                            max: 0
                        },
                        co2: {
                            min:0,
                            max: 0
                        },
                        o3: {
                            min:0,
                            max: 0
                        },
                        intensity: {
                            min:0,
                            max: 0
                        }
                    }
                }
            },
        };

        this.plantIconMap = {
            Temperature: "icon-temperature",
            Humidity: "icon-humidity",
            CO2: "icon-co2",
            O3: "icon-o3",
            Intensity: "icon-intensity",
            Shade: "icon-shade",
        }

        this.onPlantOpneEdit = this.onPlantOpneEdit.bind(this);
        this.onPlantCloseEdit = this.onPlantCloseEdit.bind(this);
        this.onPlantInputChange = this.onPlantInputChange.bind(this);
        this.onPlantSubmit = this.onPlantSubmit.bind(this);

        this.onAmbientInputChange = this.onAmbientInputChange.bind(this);
        this.onBackToPlant = this.onBackToPlant.bind(this);
        this.onSaveSettings = this.onSaveSettings.bind(this);
    }

    componentDidMount() {
        let plant_id = this.props.match.params.plant_id;

        this.getPlantData(plant_id);
        setInterval(() => {
            this.getPlantData(plant_id);
        }, 5000);

        this.getPlantSettings(plant_id);
    }

    getPlantData(plant_id) {

        /*
        this.setState({
            plant: plant
        }, () => {
            // console.log( this.state );
        });
        */

        apiHandler.get(Api.plants + '/' + plant_id)
        .then((response) => {

            if( !Object.keys(response.data).length ) {
                const errorMessage =  { response:{ status : 0, data : "Empty plant data" } };
                throw errorMessage;
            }

            this.setState({
                response: {
                    status: response.status
                },
                plant: response.data
            }, () => {
                // console.log( this.state );
            });

        }).catch((error) => {

            if( !Object.keys(error).length ) {
                error = { response:{ status : 0, data : "Can't connect server" } };
            }

            this.setState({
                response: {
                    status: error.response.status,
                    data: error.response.data
                }
            }, () => {
                console.error( this.state );
            });

        });
    }

    onPlantOpneEdit(event) {
        this.setState({
            isFormEditing: true,
            formData: {
                id:this.state.plant.id,
                name:this.state.plant.name,
                description: this.state.plant.description
            }
        });
    }

    onPlantCloseEdit(event) {
        this.setState({
            isFormEditing: false
        });
    }

    onPlantInputChange(event) {

        event.preventDefault();
        const { name, value } = event.target;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        }, () => {
                // console.log(this.state);
            }
        );
    }

    onPlantSubmit(event) {

        event.preventDefault();

        try {
            // TODO
            const formData = this.state.formData;

            apiHandler.patch(Api.plants + '/' + formData.id, formData)
            .then((response) => {

                if( !Object.keys(response.data).length ) {
                    const errorMessage =  { response:{ status : 0, data : "Empty response plants data" } };
                    throw errorMessage;
                }

                this.setState({
                    isFormEditing: false,
                    response: {
                        status: response.status
                    },
                    plant: response.data
                }, () => {
                    // console.log( this.state );
                });

            }).catch((error) => {

                if( !Object.keys(error).length ) {
                    error = { response:{ status : 0, data : "Can't connect server" } };
                }

                this.setState({
                    response: {
                        status: error.response.status,
                        data: error.response.data
                    }
                }, () => {
                    console.error( this.state );
                });

            });

        } catch(error) {
            console.error( error );
        }
    }

    onAmbientInputChange(event) {

        const targetValue = (event.target.type === 'checkbox')?  event.target.checked : (event.target.value? ( event.target.value ) : 0);
        const targetType = event.target.dataset.type;

        switch(targetType) {

            case 'temperature-enable':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    temperature: {
                                        ...prevState.formPlantSettings.manual.ambient.temperature,
                                        enable: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'temperature-min':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    temperature: {
                                        ...prevState.formPlantSettings.manual.ambient.temperature,
                                        min: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'temperature-max':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    temperature: {
                                        ...prevState.formPlantSettings.manual.ambient.temperature,
                                        max: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            case 'humidity-enable':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    humidity: {
                                        ...prevState.formPlantSettings.manual.ambient.humidity,
                                        enable: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'humidity-min':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    humidity: {
                                        ...prevState.formPlantSettings.manual.ambient.humidity,
                                        min: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'humidity-max':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    humidity: {
                                        ...prevState.formPlantSettings.manual.ambient.humidity,
                                        max: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            case 'co2-enable':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    co2: {
                                        ...prevState.formPlantSettings.manual.ambient.co2,
                                        enable: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'co2-min':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    co2: {
                                        ...prevState.formPlantSettings.manual.ambient.co2,
                                        min: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'co2-max':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    co2: {
                                        ...prevState.formPlantSettings.manual.ambient.co2,
                                        max: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            case 'o3-enable':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    o3: {
                                        ...prevState.formPlantSettings.manual.ambient.o3,
                                        enable: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'o3-min':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    o3: {
                                        ...prevState.formPlantSettings.manual.ambient.o3,
                                        min: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'o3-max':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    o3: {
                                        ...prevState.formPlantSettings.manual.ambient.o3,
                                        max: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            case 'intensity-enable':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    intensity: {
                                        ...prevState.formPlantSettings.manual.ambient.intensity,
                                        enable: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'intensity-min':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    intensity: {
                                        ...prevState.formPlantSettings.manual.ambient.intensity,
                                        min: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'intensity-max':
                this.setState( (prevState, props) => {
                    return {
                        formPlantSettings: {
                            ...prevState.formPlantSettings,
                            manual: {
                                ...prevState.formPlantSettings.manual,
                                ambient: {
                                    ...prevState.formPlantSettings.manual.ambient,
                                    intensity: {
                                        ...prevState.formPlantSettings.manual.ambient.intensity,
                                        max: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            default:
            break;
        }

    }

    getPlantSettings(plant_id) {

        apiHandler.get(Api.plants + '/' + plant_id + '/settings')
        .then((response) => {

            if( !Object.keys(response.data).length ) {
                const errorMessage =  { response:{ status : 0, data : "Empty plant settings data" } };
                throw errorMessage;
            }

            this.setState({
                response: {
                    status: response.status
                },
                formPlantSettings: response.data
            }, () => {
                // console.log( this.state );
            });

        }).catch((error) => {

            if( !Object.keys(error).length ) {
                error = { response:{ status : 0, data : "Can't connect server" } };
            }

            this.setState({
                response: {
                    status: error.response.status,
                    data: error.response.data
                }
            }, () => {
                console.error( this.state );
            });

        });
    }


    onBackToPlant(event) {
        console.log(this.props.history.goBack());
    }

    onSaveSettings(event) {

        try {
            // TODO
            const plant = this.state.plant;
            const formPlantSettings = this.state.formPlantSettings;

            apiHandler.put(Api.plants + '/' + plant.id + '/settings/manual', formPlantSettings.manual)
            .then((response) => {

                if( !Object.keys(response.data).length ) {
                    const errorMessage =  { response:{ status : 0, data : "Empty response plant settings data" } };
                    throw errorMessage;
                }

                this.setState({
                    response: {
                        status: response.status
                    },
                    formPlantSettings: {
                        ...this.state.formPlantSettings,
                        manual: response.data
                    }
                }, () => {
                    console.log( this.state );
                });

            }).catch((error) => {

                if( !Object.keys(error).length ) {
                    error = { response:{ status : 0, data : "Can't connect server" } };
                }

                this.setState({
                    response: {
                        status: error.response.status,
                        data: error.response.data
                    }
                }, () => {
                    console.error( this.state );
                });

            });

        } catch(error) {
            console.error( error );
        }
    }

    render() {

        const response = this.state.response;

        const cPlant = this.state.plant || {};
        const cPlantAmbient = (cPlant.stat)? cPlant.stat.ambient : [];

        let result = Utils.getHtmlOnloading(response);

        let content = null;
        if(result.status !== 'done') {
            content = result.html;
        } else {
            content = (
                <React.Fragment>
                    <Container fluid={true} as="section" className="top-breadcrumb px-0">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href={"/plant/"+(cPlant.id)}>
                                {cPlant.name}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{"Settings"}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Container>

                    <Container fluid={true} as="section" className="content">
                        <Card>
                            <Card.Body>
                                <Row>
                                { !cPlantAmbient.length?
                                    <Col>
                                        <LoadingIcon iconName="icon-spin6" />
                                    </Col>
                                :
                                    <React.Fragment>
                                        {cPlantAmbient.map((plant_ambient, id1) => (
                                        <Col sm={6} md={4} xl key={id1} className="text-center mb-3">
                                            <div className="mx-auto my-auto w-50">
                                                <CircularStat
                                                    percent={100}
                                                    value={Math.floor(plant_ambient.value).toFixed(2)}
                                                    unit={plant_ambient.unit}
                                                    isAlert={plant_ambient.alert}
                                                    classIcon={this.plantIconMap[plant_ambient.name]}
                                                />
                                            </div>
                                        </Col>
                                        ))}
                                    </React.Fragment>
                                }
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>

                    <Container fluid={true} as="section" className="content">
                        <h5>Plant Settings</h5>
                        <Form>
                            <Card className="mb-3">
                                <Card.Body>

                                    <Container className={"py-2"}>
                                        <Row className="mb-3">
                                            <Col xs={12}>
                                                <Form.Label><strong>Ambient Settings</strong></Form.Label>
                                            </Col>
                                            { !this.state.formPlantSettings.manual.ambient.temperature.controllable?
                                                <React.Fragment>
                                                </React.Fragment>
                                            :
                                                <Col sm={6} lg={2} className="mb-3">
                                                    <Form.Label>Temperature</Form.Label>
                                                    <Form.Check type="checkbox" data-type="temperature-enable" defaultChecked={this.state.formPlantSettings.manual.ambient.temperature.enable || false} label="Enable" onClick={this.onAmbientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="temperature-min" placeholder="Min" className="mb-1" value={this.state.formPlantSettings.manual.ambient.temperature.min || 0} onChange={this.onAmbientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="temperature-max" placeholder="Max" value={this.state.formPlantSettings.manual.ambient.temperature.max || 0} onChange={this.onAmbientInputChange.bind(this)} />
                                                </Col>
                                            }
                                            { !this.state.formPlantSettings.manual.ambient.humidity.controllable?
                                                <React.Fragment>
                                                </React.Fragment>
                                            :
                                                <Col sm={6} lg={2} className="mb-3">
                                                    <Form.Label>Humidity</Form.Label>
                                                    <Form.Check type="checkbox" data-type="humidity-enable" defaultChecked={this.state.formPlantSettings.manual.ambient.humidity.enable || false} label="Enable" onClick={this.onAmbientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="humidity-min" placeholder="Min" className="mb-1" value={this.state.formPlantSettings.manual.ambient.humidity.min || 0} onChange={this.onAmbientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="humidity-max" placeholder="Max" value={this.state.formPlantSettings.manual.ambient.humidity.max || 0} onChange={this.onAmbientInputChange.bind(this)} />
                                                </Col>
                                            }
                                            { !this.state.formPlantSettings.manual.ambient.co2.controllable?
                                                <React.Fragment>
                                                </React.Fragment>
                                            :
                                                <Col sm={6} lg={2} className="mb-3">
                                                    <Form.Label>CO2</Form.Label>
                                                    <Form.Check type="checkbox" data-type="co2-enable" defaultChecked={this.state.formPlantSettings.manual.ambient.co2.enable || false} label="Enable" onClick={this.onAmbientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="co2-min" placeholder="Min" className="mb-1" value={this.state.formPlantSettings.manual.ambient.co2.min || 0} onChange={this.onAmbientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="co2-max" placeholder="Max" value={this.state.formPlantSettings.manual.ambient.co2.max || 0} onChange={this.onAmbientInputChange.bind(this)} />
                                                </Col>
                                            }
                                            { !this.state.formPlantSettings.manual.ambient.o3.controllable?
                                                <React.Fragment>
                                                </React.Fragment>
                                            :
                                                <Col sm={6} lg={2} className="mb-3">
                                                    <Form.Label>O3</Form.Label>
                                                    <Form.Check type="checkbox" data-type="o3-enable" defaultChecked={this.state.formPlantSettings.manual.ambient.o3.enable || false} label="Enable" onClick={this.onAmbientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="o3-min" placeholder="Min" className="mb-1" value={this.state.formPlantSettings.manual.ambient.o3.min || 0} onChange={this.onAmbientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="o3-max" placeholder="Max" value={this.state.formPlantSettings.manual.ambient.o3.max || 0} onChange={this.onAmbientInputChange.bind(this)} />
                                                </Col>
                                            }
                                            { !this.state.formPlantSettings.manual.ambient.intensity.controllable?
                                                <React.Fragment>
                                                </React.Fragment>
                                            :
                                                <Col sm={6} lg={2} className="mb-3">
                                                    <Form.Label>Intensity</Form.Label>
                                                    <Form.Check type="checkbox" data-type="intensity-enable" defaultChecked={this.state.formPlantSettings.manual.ambient.intensity.enable || false} label="Enable" onClick={this.onAmbientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="intensity-min" placeholder="Min" className="mb-1" value={this.state.formPlantSettings.manual.ambient.intensity.min || 0} onChange={this.onAmbientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="intensity-max" placeholder="Max" value={this.state.formPlantSettings.manual.ambient.intensity.max || 0} onChange={this.onAmbientInputChange.bind(this)} />
                                                </Col>
                                            }

                                            <Col xs={12} className="text-right">
                                                <Button variant="primary" onClick={(e)=>{this.onSaveSettings(e)}}>
                                                    Set
                                                </Button>
                                                <hr/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <Button variant="primary" className="" href={"/plant/"+cPlant.id}>
                                                    Back
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>

                                </Card.Body>
                            </Card>
                        </Form>
                    </Container>
                </React.Fragment>
            );
        }

        return (
            <main className="page-container plant-container">
                <Container fluid={true} as="section" className="header">
                    <Jumbotron className="jumbotron-image">

                    { (this.state.isFormEditing === false)?
                        <React.Fragment>
                            <Row className="">
                                <div className="d-none d-xl-block text-absolute row" onClick={ (e)=>{this.onPlantOpneEdit(e)} }>
                                    <h2>{cPlant.name}</h2>
                                    <p>
                                        {cPlant.description}
                                    </p>
                                    <img src={plant_cover} alt={cPlant.name} />
                                </div>
                                <div className="d-xl-none row" onClick={ (e)=>{this.onPlantOpneEdit(e)} }>
                                    <img src={plant_cover} alt={cPlant.name} />
                                    <Col>
                                        <h1>{cPlant.name}</h1>
                                        <div>
                                            {cPlant.description}
                                        </div>
                                    </Col>
                                </div>
                            </Row>
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <Form onSubmit={this.onPlantSubmit}>
                                <Form.Group>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="name"
                                        onChange={this.onPlantInputChange}
                                        defaultValue={cPlant.name}
                                        placeholder="Name"
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="description"
                                        onChange={this.onPlantInputChange}
                                        defaultValue={cPlant.description}
                                        placeholder="Description"
                                    />
                                </Form.Group>

                                <Form.Group className={ "text-right" }>
                                    <Button variant="primary" type="button" className="mr-1" onClick={ (e)=>{this.onPlantCloseEdit(e)} }>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type="submit">
                                        Save
                                    </Button>
                                </Form.Group>
                            </Form>
                        </React.Fragment>
                    }

                    </Jumbotron>
                </Container>

                {content}

            </main>
        );
    }
}

export default PlantSettingssContainer;
