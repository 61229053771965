import React from 'react';
import { Container } from 'react-bootstrap';

class Copyright extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container fluid={true} className="comp-copyright text-center">
          {'Copyright © '}
          <a href="https://www.agrowlab.com/">
            AgrowLab Co., Ltd.
          </a>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Container>
      </React.Fragment>
    )
  }
}

export default Copyright;
