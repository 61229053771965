import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbarWithChildren  } from 'react-circular-progressbar';

class CircularStat extends React.Component {
    render() {
        return (
            <React.Fragment>
                <CircularProgressbarWithChildren
                    value={this.props.percent}
                >
                    <i
                        className={"icon " + this.props.classIcon + ' ' + (this.props.isAlert? this.props.classAlert : '')}
                    />
                    <div className={(this.props.isAlert? this.props.classAlert : '')}>
                        <div><strong>{this.props.value}</strong></div>
                        <div>{this.props.unit}</div>
                    </div>
                </CircularProgressbarWithChildren>
            </React.Fragment>
        )
    }
}
CircularStat.propTypes = {
    percent: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    isAlert: PropTypes.bool.isRequired,
    classIcon: PropTypes.string,
    classAlert: PropTypes.string

};
CircularStat.defaultProps = {
  classIcon: null,
  classAlert: 'animate__animated animate__bounceIn loop text-danger'
};

export default CircularStat;