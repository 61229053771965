import React from 'react';
import { Container, Row, Col, Modal, Button, Carousel } from 'react-bootstrap';
import PropTypes from 'prop-types';

import RackProfileList from '../components/RackProfileList';
import RackProfileForm from '../components/RackProfileForm';

/*
<Button variant="primary" className="mr-1" onClick={this.onEdit.bind(this, profile.id, this, this)}>
    <i className="icon icon-edit xs" />
</Button>
onEdit(id, parent1, parent2, event) {
    console.log(id);
    console.log(parent1);
    console.log(parent2);
    console.log(event);
}
*/

/*
    let valuesDay = [...this.state.valuesDay];

    let xxx = this.state.valuesDay;
    this.setState({ xxx });
*/


class ModalRackProfile extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            show: false,
            slideIndex: 0
        };

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        this.onSilideSelect = this.onSilideSelect.bind(this);

        this.onProfileEditCancel = this.onProfileEditCancel.bind(this);
        this.onProfileEditSave = this.onProfileEditSave.bind(this);

        this.refCarousel = React.createRef();
        this.refRackProfileList = React.createRef();
        this.refRackProfileForm = React.createRef();
    }

    show() {
        this.setState({ show: true });
    }

    close() {
        this.setState({ show: false });
    }

    onSilideSelect(selectedIndex, e) {
        this.setState({
            slideIndex: selectedIndex
        });
    }

    /**
    * Profile handler
    */
    afterAddProfile() {
        console.log("after Add");
        this.refs.refCarousel.next();
    }
    afterEditProfile() {
        console.log("after Edit");
        this.refs.refCarousel.next();
    }
    afterDeleteProfile() {
        console.log("after Delete");
    }
    afterSubmitProfile() {
        console.log("after Submit");
    }

    /**
    * Buttom control handler
    */
    onProfileEditCancel(event, id) {
        console.log("Edit Cancel Profile");
        this.refs.refCarousel.prev();
        this.refs.refRackProfileForm.resetForm();
    }
    onProfileEditSave(event, id) {
        console.log("Save Profile");
        this.refs.refCarousel.prev();
        this.refs.refRackProfileForm.submitForm();
    }

    render() {

        const rack_id = this.props.rack_id;

        return (
            <React.Fragment>
                <Modal
                    show={this.state.show}
                    onHide={this.close}
                    {...this.props}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.modaltitle}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Carousel
                            onSelect={this.onSilideSelect}
                            controls={false}
                            indicators={false}
                            interval={null}
                            ref="refCarousel"
                        >

                          <Carousel.Item>
                            <Container fluid={true}>

                                <RackProfileList
                                    rack_id={rack_id}
                                    afterAdd={this.afterAddProfile.bind(this)}
                                    afterEdit={this.afterEditProfile.bind(this)}
                                    afterDelete={this.afterDeleteProfile.bind(this)}
                                    ref="refRackProfileList"
                                />

                                <hr />
                                <Row className="mb-2">
                                    <Col className="text-right">
                                        <Button variant="primary" onClick={this.close}>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                          </Carousel.Item>

                          <Carousel.Item>
                            <Container fluid={true}>

                                <RackProfileForm
                                    ref="refRackProfileForm"
                                    afterSubmit={this.afterSubmitProfile.bind(this)}
                                />

                                <hr />
                                <Row className="mb-2">
                                    <Col className="text-right">
                                        <Button variant="primary" className="mr-1" onClick={(e)=>{this.onProfileEditCancel(e)}}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={(e)=>{this.onProfileEditSave(e)}}>
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                          </Carousel.Item>

                        </Carousel>
                    </Modal.Body>

                </Modal>
            </React.Fragment>
        );
    }
}

ModalRackProfile.propTypes = {
    rack_id: PropTypes.number.isRequired
};

export default ModalRackProfile;