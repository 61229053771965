import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import apiHandler from '../libs/ApiBase';

/** Layouts **/
import LoginLayout from "../layouts/LoginLayout";
import MainLayout from "../layouts/MainLayout";
import ErrorLayout from "../layouts/ErrorLayout";

/** Components **/
import { Assets, ErrorDetails, Login, /*Logout,*/ Factories, FactoryDetails, PlantDetails, PlantSettings, RackDetails } from '../pages';

class App extends Component {

  handleLogout() {
    try {
      console.log('Logging out...');
      apiHandler.defaults.headers['Authorization'] = null;
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      return (
        <Redirect to='/login' />
      );
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    return (
      <React.Fragment>

        { ( (localStorage.access_token === undefined) )?
          <React.Fragment>
            <Redirect to='/login' />
            <LoginLayout exact path="/login" component={Login} />
          </React.Fragment>
        :
          <React.Fragment>
            <Route exact path={["/", "/login", "/factory"]}>
                <Redirect to="/factories" />
            </Route>

            {/*<LoginLayout exact path="/logout" component={Logout} />*/}
            <Route exact path='/logout'>
                <this.handleLogout />
            </Route>

            <MainLayout exact path="/factories" component={Factories} />
            <MainLayout exact path="/factory/:factory_id" component={FactoryDetails} />

            <Route exact path="/plant">
                <Redirect to="/factories" />
            </Route>
            <MainLayout exact path="/plant/:plant_id" component={PlantDetails} />
            <MainLayout exact path="/plant/:plant_id/settings" component={PlantSettings} />

            <Route exact path="/rack">
                <Redirect to="/plant" />
            </Route>
            <MainLayout exact path="/rack/:rack_id" component={RackDetails} />
          </React.Fragment>
        }

        <MainLayout exact path="/assets" component={Assets} />
        {/* <ErrorLayout component={ErrorDetails}/> */}

        {/*
            <Route exact path="/" component={Login} />
            <Route exact path="/factory" component={Factory} />
            <Route exact path="/login" component={Pages.Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/factory" component={Factory} />
            <Route exact path="/plant/:plant_id" component={Plant} />
        */}

      </React.Fragment>
    )
  }
}

export default withRouter(App);
