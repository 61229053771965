import React from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Jumbotron
} from 'react-bootstrap';

import { Api } from '../configs/Api';
import { apiHandler, Utils } from '../libs';
import { LoadingIcon } from '../components';

import './Factories.scss';
import factories_cover from '../assets/images/factories-cover.jpg'
import factory_cover from '../assets/images/factory-cover.jpg'

class FactoriesContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            response: {
                status: null,
                data: null
            },
            factories: []
        };

        this.plantIconMap = {
            'Temperature': "icon-temperature",
            'Humidity': "icon-humidity",
            'CO2': "icon-co2",
            'O3': "icon-o3",
            'Intensity': "icon-intensity",
            'Shade': "icon-shade",
        }
    }

    async componentDidMount() {
        this.getFactories();
        setInterval(() => {
            this.getFactories();
        }, 5000);
    }

    getFactories() {

        apiHandler.get(Api.factories)
        .then((response) => {

            if( !Object.keys(response.data).length ) {
                const errorMessage =  { response:{ status : 0, data : "Empty factories data" } };
                throw errorMessage;
            }

            this.setState({
                response: {
                    status: response.status
                },
                factories: response.data
            }, () => {
                // console.log( this.state );
            });

        }).catch((error) => {

            if( !Object.keys(error).length ) {
                error = { response:{ status : 0, data : "Can't connect server" } };
            }

            this.setState({
                response: {
                    status: error.response.status,
                    data: error.response.data
                }
            }, () => {
                console.error( this.state );
            });

        });
    }

    render() {

        const response = this.state.response;
        const cFactories = this.state.factories || [];

        let result = Utils.getHtmlOnloading(response);

        let content = null;
        if(result.status !== 'done') {
            content = result.html;
        } else {
            content = (
                <React.Fragment>
                    <Container fluid={true} as="section" className="content">
                        <Row>
                        { !cFactories.length?
                            <Col>
                                <LoadingIcon iconName="icon-spin6" />
                            </Col>
                        :
                            <React.Fragment>
                                {cFactories.map((_factory, _id1) => (
                                <Col key={_id1} md={4} lg={3} className="mb-3">
                                    <Card>
                                        <Card.Img variant="top" src={factory_cover} />
                                        <Card.Body>
                                            <Card.Title>{_factory.name}</Card.Title>
                                            <Card.Text>
                                              {_factory.description}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="text-right bg-white">
                                            <Button variant="primary" href={'/factory/' + _factory.id}>Details</Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                                ))}
                            </React.Fragment>
                        }
                        </Row>
                    </Container>
                </React.Fragment>
            );
        }

        return (
            <main className="page-container factories-container">

                <Container fluid={true} as="section" className="header px-0">
                    <Jumbotron fluid={true} className="jumbotron-image">
                        <figure className="figure">
                            <img src={factories_cover} alt="" />
                        </figure>
                    </Jumbotron>
                </Container>

                {content}

            </main>
        );
    }
}

export default FactoriesContainer;