import React from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    Button
} from 'react-bootstrap';

import apiHandler from '../libs/ApiBase'
import { Api } from '../configs/Api';
// import PropTypes from 'prop-types';

import Copyright from '../components/Copyright';
import './Login.scss';

class LoginContainer extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            validated: false,
            formData: {},
            formError: {
                username: null,
                password: null,
                detail: null
            }
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onInputChange(event) {

        event.preventDefault();
        const { name, value } = event.target;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            },
            formError: {
                username: this.state.formError.username,
                password: this.state.formError.password,
                detail: null
            }
        }, () => {
                // console.log(this.state);
            }
        );
    }

    async onSubmit(event) {

        event.preventDefault();
        // const form = event.currentTarget;

        if (event.currentTarget.checkValidity() === false) {
            event.stopPropagation();
        }

        this.setState({
            validated: true
        });

        try {
            const formData = this.state.formData;
            const response = await apiHandler.post(Api.login, {
                username: formData.username,
                password: formData.password
            });

            apiHandler.defaults.headers['Authorization'] = "JWT " + response.data.access;
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            window.location.href = '/factory';
        } catch (error) {

            let response = error.response;

            if( !Object.keys(error).length ) {
                error = { response:{ status : 500, data : {detail:"Can't connect server"} } };
            }

            switch(error.response.status) {
                case 400:
                    this.setState({
                        formError: {
                            username: (error.response.data['username'])? error.response.data['username'][0] : null,
                            password: (error.response.data['password'])? error.response.data['password'][0] : null
                        }
                    }, () => {
                            console.log(this.state);
                        }
                    );
                break;

                case 401:
                    this.setState({
                        formError: {
                            detail: (error.response.data['detail'] || null)
                        }
                    }, () => {
                            console.log(this.state);
                        }
                    );
                break;

                case 500:
                    this.setState({
                        formError: {
                            detail: (error.response.data['detail'] || null)
                        }
                    }, () => {
                            console.log(this.state);
                        }
                    );
                break;

                default:
                    console.error(error);
                break;
            }

        }
    }

    render() {

        return (
            <main className="login-container">
                <Container fluid={true} as="section" className="height-100vh">
                    <Row className="height-100vh">
                        <Col className="bg-image"></Col>
                        <Col md="3" className="form pt-5 px-5">
                            <h1 className="text-hide form.logo">AgrowLab</h1>
                            <Form onSubmit={this.onSubmit} noValidate validated={this.state.validated}>
                                <Form.Group>
                                    <div className="inner-addon left-addon">
                                        <i className="icon icon-login-username" />
                                        <Form.Control
                                            required
                                            type="text"
                                            name="username"
                                            onChange={this.onInputChange}
                                            placeholder="Username"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {this.state.formError.username}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <div className="inner-addon left-addon">
                                        <i className="icon icon-login-password" />
                                        <Form.Control
                                            required
                                            type="password"
                                            name="password"
                                            onChange={this.onInputChange}
                                            placeholder="Password"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {this.state.formError.password}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    <p className="text-center text-danger">
                                        {this.state.formError.detail}
                                    </p>
                                </Form.Group>
                                <Form.Group className={ "text-right" /*d-flex flex-row-reverse*/ }>
                                    <Button variant="success" type="submit">
                                        Login
                                    </Button>
                                </Form.Group>
                            </Form>
                            <Copyright />
                        </Col>
                    </Row>
                </Container>
            </main>
        );
    }
}

// LoginContainer.defaultProps = {
//     name: 'Stranger'
// };

// LoginContainer.propTypes = {
//     name: PropTypes.object.isRequired,
// };

export default LoginContainer;