import React from 'react';
import { Container } from 'react-bootstrap';

class LogoutContainer extends React.Component {

    render() {
        return (
            <Container as="h5" className="text-center">
                Thank you for using our services
            </Container>
        )
    }
}

export default LogoutContainer;