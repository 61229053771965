import React from 'react';
import LoadingIcon from '../components/LoadingIcon';
import HttpError from '../components/HttpError';

function getHtmlOnloading(response) {

    let status = null;
    let html = null;

    if(response.status === null) {
        status = 'loading';
        html = (<LoadingIcon iconName="icon-spin6" />);
    } else {
        status = 'done';
        if(response.status !== 200) {
            status = 'error';
            html = (<HttpError response={response} />);
        }
    }

    return {
        status: status,
        html: html
    };
};

export default {
    getHtmlOnloading
}