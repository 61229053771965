import React from 'react';
import { Route } from 'react-router-dom';

import "./BootstrapTheme-1.scss";
import './CustomStyle.scss';

const LoginLayout = ({ children }) => (
    <React.Fragment>
      {children}
    </React.Fragment>
  );

  const LoginLayoutRoute = ({component: Component, ...rest}) => {
    return (
      <Route {...rest} render={matchProps => (
        <LoginLayout>
            <Component {...matchProps} />
        </LoginLayout>
      )} />
    )
  };

export default LoginLayoutRoute;