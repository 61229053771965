import React from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Jumbotron,
    Breadcrumb,
    Form
} from 'react-bootstrap';

import TimePicker from 'react-bootstrap-time-picker';
import { timeFromInt } from 'time-number';

import { Api } from '../configs/Api';
import { apiHandler, Utils } from '../libs';
import { LoadingIcon, CircularStat } from '../components';

import ModalRackProfile from '../components/ModalRackProfile';

import './RackDetails.scss';
import rack_cover from '../assets/images/rack-cover.jpg'

const rackSettings = {
    mode: "MANUAL",
    profileId: 1,
    profileList: [{
        id: 1,
        name: "Kana"
    }, {
        id: 2,
        name: "Salad"
    },{
        id: 3,
        name: "Cat"
    }]
};

class RackDetailsContainer extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.rackIconMap = {
            Temperature: 'icon-temperature',
            EC: 'icon-ec',
            pH: 'icon-ph',
        }

        this.state = {
            response: {
                status: null,
                data: null
            },
            rack: {},
            formData: {},
            isFormEditing: false,
            formRackSettings: {
                mode: rackSettings.mode,
                auto: {
                    profileId: rackSettings.profileId
                },
                manual: {
                    nutrient: {
                        temperature: {
                            min:0,
                            max:0
                        },
                        ec: {
                            min:0,
                            max:0
                        },
                        ph: {
                            min:0,
                            max:0
                        }
                    },
                    growlight: []
                }
            }
        };

        this.onRackOpenEdit = this.onRackOpenEdit.bind(this);
        this.onRackCloseEdit = this.onRackCloseEdit.bind(this);
        this.onRackInputChange = this.onRackInputChange.bind(this);
        this.onRackSubmit = this.onRackSubmit.bind(this);

        this.onRackSettingsModeChange = this.onRackSettingsModeChange.bind(this);
        this.onRackSettingsProfileChange = this.onRackSettingsProfileChange.bind(this);
        this.onOpenModalRackProfile = this.onOpenModalRackProfile.bind(this);
        this.onSetAuto = this.onSetAuto.bind(this);
        this.refModalRackProfile = React.createRef();

        this.onNutrientInputChange = this.onNutrientInputChange.bind(this);
        this.onSetNutrientManual = this.onSetNutrientManual.bind(this);

        this.onGrowLightPatternChange = this.onGrowLightPatternChange.bind(this);
        this.onGrowLightTimeChange = this.onGrowLightTimeChange.bind(this);
        this.onSetGrowLightManual = this.onSetGrowLightManual.bind(this);

    }

    componentDidMount() {
        let rack_id = this.props.match.params.rack_id;

        this.getRackData(rack_id);
        setInterval(() => {
            this.getRackData(rack_id);
        }, 5000);

        this.getRackSettings(rack_id);
    }

    getRackData(rack_id) {

        /*
        this.setState({
            rack: rack
        }, () => {
            // console.log( this.state );
        });
        */

        apiHandler.get(Api.racks + '/' + rack_id)
        .then((response) => {

            if( !Object.keys(response.data).length ) {
                const errorMessage =  { response:{ status : 0, data : "Empty rack data" } };
                throw errorMessage;
            }

            this.setState({
                response: {
                    status: response.status
                },
                rack: response.data
            }, () => {
                // console.log( this.state );
            });

        }).catch((error) => {

            if( !Object.keys(error).length ) {
                error = { response:{ status : 0, data : "Can't connect server" } };
            }

            this.setState({
                response: {
                    status: error.response.status,
                    data: error.response.data
                }
            }, () => {
                console.error( this.state );
            });

        });
    }

    getRackSettings(rack_id) {

        apiHandler.get(Api.racks + '/' + rack_id + '/settings')
        .then((response) => {

            if( !Object.keys(response.data).length ) {
                const errorMessage =  { response:{ status : 0, data : "Empty rack settings data" } };
                throw errorMessage;
            }

            this.setState({
                response: {
                    status: response.status
                },
                formRackSettings: response.data
            }, () => {
                // console.log( this.state );
            });

        }).catch((error) => {

            if( !Object.keys(error).length ) {
                error = { response:{ status : 0, data : "Can't connect server" } };
            }

            this.setState({
                response: {
                    status: error.response.status,
                    data: error.response.data
                }
            }, () => {
                console.error( this.state );
            });

        });
    }

    onRackOpenEdit(event) {
        this.setState({
            isFormEditing: true,
            formData: {
                id:this.state.rack.id,
                name:this.state.rack.name,
                description: this.state.rack.description
            }
        });
    }

    onRackCloseEdit(event) {
        this.setState({
            isFormEditing: false
        });
    }

    onRackInputChange(event) {

        event.preventDefault();
        const { name, value } = event.target;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        }, () => {
                // console.log(this.state);
            }
        );
    }

    onRackSubmit(event) {

        event.preventDefault();

        const formData = this.state.formData;

        apiHandler.patch(Api.racks + '/' + formData.id, formData)
        .then((response) => {

            if( !Object.keys(response.data).length ) {
                const errorMessage =  { response:{ status : 0, data : "Empty response racks data" } };
                throw errorMessage;
            }

            this.setState({
                isFormEditing: false,
                response: {
                    status: response.status
                },
                rack: response.data
            }, () => {
                // console.log( this.state );
            });

        }).catch((error) => {

            if( !Object.keys(error).length ) {
                error = { response:{ status : 0, data : "Can't connect server" } };
            }

            this.setState({
                response: {
                    status: error.response.status,
                    data: error.response.data
                }
            }, () => {
                console.error( this.state );
            });

        });
    }

    onRackSettingsModeChange(event) {
        const value = event.target.value;

        this.setState({
            formRackSettings: {
                ...this.state.formRackSettings,
                mode: value
            }
        }, () => {
                // console.log(this.state);
            }
        );
    }
    onRackSettingsProfileChange(event) {
        const value = event.target.value;

        this.setState({
            formRackSettings: {
                ...this.state.formRackSettings,
                auto: {
                    ...this.state.formRackSettings.auto,
                    profileId: value
                }
            }
        }, () => {
                // console.log(this.state);
            }
        );
    }
    onOpenModalRackProfile(event) {
        this.refs.refModalRackProfile.show();
    }
    onSetAuto(event) {
        console.log(this.state);
    }


    onNutrientInputChange(event) {

        const targetValue = (event.target.type === 'checkbox')?  event.target.checked : (event.target.value? ( event.target.value ) : 0);
        const targetType = event.target.dataset.type;

        switch(targetType) {

            case 'temperature-enable':
                this.setState( (prevState, props) => {
                    return {
                        formRackSettings: {
                            ...prevState.formRackSettings,
                            manual: {
                                ...prevState.formRackSettings.manual,
                                nutrient: {
                                    ...prevState.formRackSettings.manual.nutrient,
                                    temperature: {
                                        ...prevState.formRackSettings.manual.nutrient.temperature,
                                        enable: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'temperature-min':
                this.setState( (prevState, props) => {
                    return {
                        formRackSettings: {
                            ...prevState.formRackSettings,
                            manual: {
                                ...prevState.formRackSettings.manual,
                                nutrient: {
                                    ...prevState.formRackSettings.manual.nutrient,
                                    temperature: {
                                        ...prevState.formRackSettings.manual.nutrient.temperature,
                                        min: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'temperature-max':
                this.setState( (prevState, props) => {
                    return {
                        formRackSettings: {
                            ...prevState.formRackSettings,
                            manual: {
                                ...prevState.formRackSettings.manual,
                                nutrient: {
                                    ...prevState.formRackSettings.manual.nutrient,
                                    temperature: {
                                        ...prevState.formRackSettings.manual.nutrient.temperature,
                                        max: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            case 'ec-enable':
                this.setState( (prevState, props) => {
                    return {
                        formRackSettings: {
                            ...prevState.formRackSettings,
                            manual: {
                                ...prevState.formRackSettings.manual,
                                nutrient: {
                                    ...prevState.formRackSettings.manual.nutrient,
                                    ec: {
                                        ...prevState.formRackSettings.manual.nutrient.ec,
                                        enable: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'ec-min':
                this.setState( (prevState, props) => {
                    return {
                        formRackSettings: {
                            ...prevState.formRackSettings,
                            manual: {
                                ...prevState.formRackSettings.manual,
                                nutrient: {
                                    ...prevState.formRackSettings.manual.nutrient,
                                    ec: {
                                        ...prevState.formRackSettings.manual.nutrient.ec,
                                        min: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'ec-max':
                this.setState( (prevState, props) => {
                    return {
                        formRackSettings: {
                            ...prevState.formRackSettings,
                            manual: {
                                ...prevState.formRackSettings.manual,
                                nutrient: {
                                    ...prevState.formRackSettings.manual.nutrient,
                                    ec: {
                                        ...prevState.formRackSettings.manual.nutrient.ec,
                                        max: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            case 'ph-enable':
                this.setState( (prevState, props) => {
                    return {
                        formRackSettings: {
                            ...prevState.formRackSettings,
                            manual: {
                                ...prevState.formRackSettings.manual,
                                nutrient: {
                                    ...prevState.formRackSettings.manual.nutrient,
                                    ph: {
                                        ...prevState.formRackSettings.manual.nutrient.ph,
                                        enable: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'ph-min':
                this.setState( (prevState, props) => {
                    return {
                        formRackSettings: {
                            ...prevState.formRackSettings,
                            manual: {
                                ...prevState.formRackSettings.manual,
                                nutrient: {
                                    ...prevState.formRackSettings.manual.nutrient,
                                    ph: {
                                        ...prevState.formRackSettings.manual.nutrient.ph,
                                        min: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;
            case 'ph-max':
                this.setState( (prevState, props) => {
                    return {
                        formRackSettings: {
                            ...prevState.formRackSettings,
                            manual: {
                                ...prevState.formRackSettings.manual,
                                nutrient: {
                                    ...prevState.formRackSettings.manual.nutrient,
                                    ph: {
                                        ...prevState.formRackSettings.manual.nutrient.ph,
                                        max: targetValue
                                    }
                                }
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            default:
            break;
        }
    }

    onSetNutrientManual(event) {

        const rack = this.state.rack;
        const nutrient = this.state.formRackSettings.manual.nutrient;
        const _data = {
            nutrient: nutrient
        };

        apiHandler.put(Api.racks + '/' + rack.id + '/settings/manual', _data)
        .then((response) => {

            if( !Object.keys(response.data).length ) {
                const errorMessage =  { response:{ status : 0, data : "Empty response manual settings data" } };
                throw errorMessage;
            }

            this.getRackSettings(rack.id);

        }).catch((error) => {

            if( !Object.keys(error).length ) {
                error = { response:{ status : 0, data : "Can't connect server" } };
            }

            this.setState({
                response: {
                    status: error.response.status,
                    data: error.response.data
                }
            }, () => {
                console.error( this.state );
            });

        });
    }

    onGrowLightPatternChange(event) {

        const rack = this.state.rack;
        const {id, value} = event.target.dataset;
        const _data = {
            growlight: [{
                id: id,
                pattern: value
            }]
        };

        apiHandler.put(Api.racks + '/' + rack.id + '/settings/manual', _data)
        .then((response) => {

            if( !Object.keys(response.data).length ) {
                const errorMessage =  { response:{ status : 0, data : "Empty response manual settings data" } };
                throw errorMessage;
            }

            this.getRackSettings(rack.id);

        }).catch((error) => {

            if( !Object.keys(error).length ) {
                error = { response:{ status : 0, data : "Can't connect server" } };
            }

            this.setState({
                response: {
                    status: error.response.status,
                    data: error.response.data
                }
            }, () => {
                console.error( this.state );
            });

        });
    }


    onGrowLightTimeChange(event, value, index, targetType, id) {

        switch(targetType) {

            case 'growlight-ontime':
                this.setState( (prevState, props) => {

                    let growlight = prevState.formRackSettings.manual.growlight;
                    growlight[index] = Object.assign(
                        growlight[index],
                        {
                            onTime: timeFromInt(value)
                        }
                    );

                    return {
                        formRackSettings: {
                            ...prevState.formRackSettings,
                            manual: {
                                ...prevState.formRackSettings.manual,
                                growlight: growlight
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            case 'growlight-offtime':
                this.setState( (prevState, props) => {

                    let growlight = prevState.formRackSettings.manual.growlight;
                    growlight[index] = Object.assign(
                        growlight[index],
                        {
                            offTime: timeFromInt(value)
                        }
                    );

                    return {
                        formRackSettings: {
                            ...prevState.formRackSettings,
                            manual: {
                                ...prevState.formRackSettings.manual,
                                growlight: growlight
                            }
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            default:
            break;
        }

    }

    onSetGrowLightManual(event) {
        const index = event.target.dataset.index;
        const growlight = this.state.formRackSettings.manual.growlight[index];

        const rack = this.state.rack;
        const _data = {
            growlight: [growlight]
        };

        apiHandler.put(Api.racks + '/' + rack.id + '/settings/manual', _data)
        .then((response) => {

            if( !Object.keys(response.data).length ) {
                const errorMessage =  { response:{ status : 0, data : "Empty response manual settings data" } };
                throw errorMessage;
            }

            this.getRackSettings(rack.id);

        }).catch((error) => {

            if( !Object.keys(error).length ) {
                error = { response:{ status : 0, data : "Can't connect server" } };
            }

            this.setState({
                response: {
                    status: error.response.status,
                    data: error.response.data
                }
            }, () => {
                console.error( this.state );
            });

        });
    }


    renderGrowLightPattern(growlight, index) {

        const growlightId = (growlight.id) || 0;
        const growlightName = (growlight.name) || 'Unknow';
        const activePatern = (growlight.pattern) || 0;
        const patternList = (growlight.patternList) || [];
        const growlighOnTime = (growlight.onTime) || '00:00:00';
        const growlighOffTime = (growlight.offTime) || '00:00:00';

        let rows = [];

        if(!patternList.length) {
            rows.push(
                <Col key={0}>
                    <LoadingIcon iconName="icon-spin6" />
                </Col>
            );
        } else {

            rows.push(
                <React.Fragment key={0}>
                    <Col xs={12} className="mb-2">
                        <em>{growlightName}</em>
                    </Col>
                </React.Fragment>
            );

            patternList.map((_pattern, id1) => (
                rows.push(
                    <Col key={id1+1} xs={6} sm={4} md={3} lg={3} xl={2} className="mb-2">
                        <Button variant="primary" className={ "w-100" + ((activePatern===_pattern.id)? ' active' : '') } data-index={index} data-id={growlightId} data-value={_pattern.id} onClick={(e)=>{this.onGrowLightPatternChange(e)}}>
                            {_pattern.name}
                        </Button>
                    </Col>
                )
            ));


            rows.push(
                <Col key={999} xs={12}>
                    <Row className="mb-3">
                        <Col sm={6} className="mb-2">
                            <Form.Label>On Time</Form.Label>
                            <TimePicker format={24} step={30} value={growlighOnTime} onChange={ (e)=>{this.onGrowLightTimeChange(this, e, index, "growlight-ontime", growlightId)} } />
                        </Col>
                        <Col sm={6} className="mb-2">
                            <Form.Label>Off Time</Form.Label>
                            <TimePicker format={24} step={30} value={growlighOffTime} onChange={ (e)=>{this.onGrowLightTimeChange(this, e, index, "growlight-offtime", growlightId)} } />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-right">
                            <Button variant="primary" data-index={index} onClick={(e)=>{this.onSetGrowLightManual(e)}}>
                                Set
                            </Button>
                            <hr/>
                        </Col>
                    </Row>
                </Col>
            );
        }

        return (
            <Form.Row>
            {rows}
            </Form.Row>
        );
    }

    render() {

        const response = this.state.response;

        const cRack = this.state.rack || {};
        const cRackNutrient = (cRack.stat)? cRack.stat.nutrient : [];
        const cRackPlant = (cRack.plant) ? cRack.plant : { id: null, name: null };

        let result = Utils.getHtmlOnloading(response);

        let content = null;
        if(result.status !== 'done') {
            content = result.html;
        } else {
            content = (
                <React.Fragment>
                    <Container fluid={true} as="section" className="top-breadcrumb px-0">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href={"/plant/"+(cRackPlant.id)}>
                                {cRackPlant.name}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{cRack.name}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Container>

                    <Container fluid={true} as="section" className="content">
                        <Card>
                            <Card.Body>
                                <Row>
                                { !cRackNutrient.length?
                                    <Col>
                                        <LoadingIcon iconName="icon-spin6" />
                                    </Col>
                                :
                                    <React.Fragment>
                                        {cRackNutrient.map((rack_nutrient, id1) => (
                                        <Col key={id1} sm={6} md={4} xl className="text-center mb-3">
                                            <div className="mx-auto my-auto w-50">
                                                <CircularStat
                                                    percent={100}
                                                    value={Math.floor(rack_nutrient.value).toFixed(2)}
                                                    unit={rack_nutrient.unit}
                                                    isAlert={rack_nutrient.alert}
                                                    classIcon={this.rackIconMap[rack_nutrient.name]}
                                                />
                                            </div>
                                        </Col>
                                        ))}
                                    </React.Fragment>
                                }
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>

                    <Container fluid={true} as="section" className="content">

                        <h5>Rack Settings</h5>
                        <Form>
                            {/*
                            <Card className="mb-3 d-none">
                                <Card.Body>
                                    <Row className="mb-1">
                                        <Col>
                                            <Form.Check
                                                type="radio"
                                                label="Auto"
                                                defaultValue="AUTO"
                                                checked={(this.state.formRackSettings.mode === "AUTO")? true : false}
                                                onChange={this.onRackSettingsModeChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Container className={"py-2" + ((this.state.formRackSettings.mode === "AUTO")? "" : " disabled")}>
                                        <Row className="mb-3">
                                            <Col sm={3}>
                                                <Form.Label>Profile</Form.Label>
                                            </Col>
                                            <Col xs={7}>
                                                <Form.Control
                                                    as="select"
                                                    onChange={this.onRackSettingsProfileChange}
                                                    defaultValue={this.state.formRackSettings.profileId}
                                                >
                                                    <option value="">
                                                        -- Please Select --
                                                    </option>

                                                    {rackSettings.profileList.map((_profile, _id1) => (
                                                    <option key={_id1}
                                                        value={_profile.id}
                                                    >
                                                        {_profile.name}
                                                    </option>
                                                    ))}

                                                </Form.Control>
                                            </Col>
                                            <Col className="text-right">

                                                <ModalRackProfile
                                                    rack_id = {cRack.id}
                                                    ref="refModalRackProfile"
                                                    size="lg"
                                                    backdrop="static"
                                                    centered
                                                    modaltitle = "Profile Settings"
                                                />

                                                <Button variant="primary" onClick={this.onOpenModalRackProfile}>
                                                    <i className={"icon icon-cog-alt xs pr-1"} alt="Profile Settings" />
                                                     Settings
                                                </Button>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={3}>
                                                <Form.Label>Progress</Form.Label>
                                            </Col>
                                            <Col>
                                                1/90
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-right">
                                                <Button variant="primary" onClick={(e)=>{this.onSetAuto(e)}}>
                                                    Start
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                            </Card>
                            */}

                            <Card className="mb-3">
                                <Card.Body>
                                    <Row className="mb-1 d-none">
                                        <Col>
                                            <Form.Check
                                                type="radio"
                                                label="Manual"
                                                defaultValue="MANUAL"
                                                checked={(this.state.formRackSettings.mode === "MANUAL")? true : false}
                                                onChange={this.onRackSettingsModeChange}
                                            />
                                        </Col>
                                    </Row>

                                    <Container className={"py-2" + ((this.state.formRackSettings.mode === "MANUAL")? "" : " disabled")}>
                                        <Row className="mb-3">
                                            <Col xs={12}>
                                                <Form.Label><strong>Nutrient Settings</strong></Form.Label>
                                            </Col>
                                            { !this.state.formRackSettings.manual.nutrient.temperature.controllable?
                                                <React.Fragment>
                                                </React.Fragment>
                                            :
                                                <Col sm={6} lg={4} className="mb-3">
                                                    <Form.Label>Temperature</Form.Label>
                                                    <Form.Check type="checkbox" data-type="temperature-enable" defaultChecked={this.state.formRackSettings.manual.nutrient.temperature.enable || false} label="Enable" onClick={this.onNutrientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="temperature-min" placeholder="Min" className="mb-1" value={this.state.formRackSettings.manual.nutrient.temperature.min || 0} onChange={this.onNutrientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="temperature-max" placeholder="Max" value={this.state.formRackSettings.manual.nutrient.temperature.max || 0} onChange={this.onNutrientInputChange.bind(this)} />
                                                </Col>
                                            }
                                            { !this.state.formRackSettings.manual.nutrient.ec.controllable?
                                                <React.Fragment>
                                                </React.Fragment>
                                            :
                                                <Col sm={6} lg={4} className="mb-3">
                                                    <Form.Label>EC</Form.Label>
                                                    <Form.Check type="checkbox" data-type="ec-enable" defaultChecked={this.state.formRackSettings.manual.nutrient.ec.enable || false} label="Enable" onClick={this.onNutrientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="ec-min" placeholder="Min" className="mb-1" value={this.state.formRackSettings.manual.nutrient.ec.min || 0} onChange={this.onNutrientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="ec-max" placeholder="Max" value={this.state.formRackSettings.manual.nutrient.ec.max || 0} onChange={this.onNutrientInputChange.bind(this)} />
                                                </Col>
                                            }
                                            { !this.state.formRackSettings.manual.nutrient.ph.controllable?
                                                <React.Fragment>
                                                </React.Fragment>
                                            :
                                                <Col sm={6} lg={4} className="mb-3">
                                                    <Form.Label>pH</Form.Label>
                                                    <Form.Check type="checkbox" data-type="ph-enable" defaultChecked={this.state.formRackSettings.manual.nutrient.ph.enable || false} label="Enable" onClick={this.onNutrientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="ph-min" placeholder="Min" className="mb-1" value={this.state.formRackSettings.manual.nutrient.ph.min || 0} onChange={this.onNutrientInputChange.bind(this)} />
                                                    <Form.Control type="number" data-type="ph-max" placeholder="Max" value={this.state.formRackSettings.manual.nutrient.ph.max || 0} onChange={this.onNutrientInputChange.bind(this)} />
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <Button variant="primary" onClick={(e)=>{this.onSetNutrientManual(e)}}>
                                                    Set
                                                </Button>
                                                <hr/>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            { !this.state.formRackSettings.manual.growlight.length?
                                                <React.Fragment>
                                                </React.Fragment>
                                            :
                                                <Col xs={12}>
                                                    <Form.Label><strong>Grow Light Pattern</strong></Form.Label>
                                                </Col>
                                            }

                                            {/*<this.renderGrowLightPattern /> */}

                                            {this.state.formRackSettings.manual.growlight.map((growlight, _id1) => (
                                                <React.Fragment key={_id1}>
                                                    <Col xs={12}>
                                                        {this.renderGrowLightPattern(growlight, _id1)}
                                                    </Col>
                                                </React.Fragment>
                                            ))}
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <Button variant="primary" className="" href={"/plant/"+cRackPlant.id}>
                                                    Back
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                            </Card>
                        </Form>
                    </Container>
                </React.Fragment>
            );
        }

        return (
            <main className="page-container rack-container">

                <Container fluid={true} as="section" className="header px-0">
                    <Jumbotron fluid={true} className="jumbotron-image">

                    { (this.state.isFormEditing === false)?
                        <React.Fragment>
                            <div className="d-none d-xl-block text-absolute" onClick={ (e)=>{this.onRackOpenEdit(e)} }>
                                <figure className="figure">
                                    <h2>{cRack.name}</h2>
                                    <p>
                                        {cRack.description}
                                    </p>
                                    <img src={rack_cover} alt={cRack.name} />
                                </figure>
                            </div>
                            <div className="d-xl-none" onClick={ (e)=>{this.onRackOpenEdit(e)} }>
                                <figure className="figure">
                                    <img src={rack_cover} alt={cRack.name} />
                                    <h1>{cRack.name}</h1>
                                    <p>
                                        {cRack.description}
                                    </p>
                                </figure>
                            </div>
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <Form onSubmit={this.onRackSubmit}>
                                <Form.Group>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="name"
                                        onChange={this.onRackInputChange}
                                        defaultValue={cRack.name}
                                        placeholder="Name"
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="description"
                                        onChange={this.onRackInputChange}
                                        defaultValue={cRack.description}
                                        placeholder="Description"
                                    />
                                </Form.Group>

                                <Form.Group className={ "text-right" }>
                                    <Button variant="primary" type="button" className="mr-1" onClick={ (e)=>{this.onRackCloseEdit(e)} }>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type="submit">
                                        Save
                                    </Button>
                                </Form.Group>
                            </Form>
                        </React.Fragment>
                    }

                    </Jumbotron>
                </Container>

                {content}

            </main>
        );
    }
}

export default RackDetailsContainer;
