import React from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

class RackProfileForm extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            formData: {
                countDayRow: 0,
                valuesDay: [""],
                valuesTemperature: [""],
                valuesEc: [""],
                valuesPh: [""],
                valuesBrightness: [""]
            }
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.resetForm();
    }

    resetForm() {
        this.setState( (prevState, props) => {
            return {
                formData: {
                    countDayRow: 1,
                    valuesDay: [""],
                    valuesTemperature: [""],
                    valuesEc: [""],
                    valuesPh: [""],
                    valuesBrightness: [""]
                }
            };
        });
    }

    submitForm() {
        this.form.dispatchEvent(new Event('submit', { cancelable: true }));
    }

    onSubmit(event) {
        event.preventDefault();
        console.log("onSubmit");

        try {
            // TODO
            const formData = this.state.formData;

            console.log( formData );

            // const rack_id = this.props.rack_id;


            // apiHandler.get(Api.racks + '/' + rack_id + '/profiles')
            // .then((response) => {

            //     let data = {};

            //     // console.log(response.data);
            //     // if( !Object.keys(response.data).length ) {
            //     //     throw "Empty rack profile data";
            //     // }
            //     data = response.data;

            //     this.setState({
            //         response: {
            //             status: response.status
            //         },
            //         profile: data
            //     }, () => {
            //         console.log( this.state );
            //     });

            // }).catch((error) => {

            //     const response = error.response;

            //     this.setState({
            //         response: {
            //             status: response.status,
            //             data: response.data
            //         },
            //         profile: []
            //     }, () => {
            //         console.error( this.state );
            //     });

            // });


            if(typeof this.props.afterSubmit === 'function'){
                this.props.afterSubmit();
            }

            this.resetForm();

        } catch(error) {
            console.error( error );
        }
    }

    renderDailyRow() {

        const countDayRow = this.state.formData.countDayRow;
        const valuesDay = this.state.formData.valuesDay;
        const valuesTemperature = this.state.formData.valuesTemperature;
        const valuesEc = this.state.formData.valuesEc;
        const valuesPh = this.state.formData.valuesPh;
        const valuesBrightness = this.state.formData.valuesBrightness;

        let rows = [];

        for (let i = 0; i < countDayRow; i++) {

            let day = valuesDay[i];
            let temperature = valuesTemperature[i];
            let ec = valuesEc[i];
            let ph = valuesPh[i];
            let brightness = valuesBrightness[i];
            let buttonHtml = <Button variant="primary" onClick={this.onRemoveDailyRow.bind(this, i)}>
                                <i className="icon icon-minus xs" />
                            </Button>;
            if( i === 0) {
                buttonHtml = null;
            }
            rows.push(
                <Form.Row key={i} className="mb-1">
                    <Col xs={2}>
                        <Form.Control type="number" value={day||''} name={"days["+i+"]"} data-type="day" onChange={this.onRowInputChange.bind(this, i)}  placeholder="Day" />
                    </Col>
                    <Col xs={2}>
                        <Form.Control type="number" value={temperature||''} name={"temperature["+i+"]"} data-type="temperature" onChange={this.onRowInputChange.bind(this, i)} placeholder="Temperature" />
                    </Col>
                    <Col xs={2}>
                        <Form.Control type="number" value={ec||''} name={"ec["+i+"]"} data-type="ec" onChange={this.onRowInputChange.bind(this, i)} placeholder="EC" />
                    </Col>
                    <Col xs={2}>
                        <Form.Control type="number" value={ph||''} name={"ph["+i+"]"} data-type="ph" onChange={this.onRowInputChange.bind(this, i)} placeholder="pH" />
                    </Col>
                    <Col xs={2}>
                        <Form.Control type="number" value={brightness||''} name={"brightness["+i+"]"} data-type="brightness" onChange={this.onRowInputChange.bind(this, i)} placeholder="Brightness" />
                    </Col>
                    <Col>
                        {buttonHtml}
                    </Col>
                </Form.Row>
            );
        }

        return rows;
    }

    onAddDailyRow(event){

        // this.setState( (prevState) => ({ valuesDay: [...prevState.valuesDay, '']}) );

        this.setState( (prevState, props) => {
            return {
                formData: {
                    countDayRow: (prevState.formData.countDayRow+1),
                    valuesDay: [...prevState.formData.valuesDay, ''],
                    valuesTemperature: [...prevState.formData.valuesTemperature, ''],
                    valuesEc: [...prevState.formData.valuesEc, ''],
                    valuesPh: [...prevState.formData.valuesPh, ''],
                    valuesBrightness: [...prevState.formData.valuesBrightness, '']
                }
            };
        });
    }

    onRemoveDailyRow(i, event){

         // let valuesDay = [...this.state.valuesDay];
         // valuesDay.splice(i,1);
         // this.setState({ valuesDay });

        this.setState( (prevState, props) => {

            let valuesDay = [...prevState.formData.valuesDay];
            let valuesTemperature = [...prevState.formData.valuesTemperature];
            let valuesEc = [...prevState.formData.valuesEc];
            let valuesPh = [...prevState.formData.valuesPh];
            let valuesBrightness = [...prevState.formData.valuesBrightness];

            valuesDay.splice(i,1);
            valuesTemperature.splice(i,1);
            valuesEc.splice(i,1);
            valuesPh.splice(i,1);
            valuesBrightness.splice(i,1);

            return {
                formData: {
                    countDayRow: (prevState.formData.countDayRow-1),
                    valuesDay: valuesDay,
                    valuesTemperature: valuesTemperature,
                    valuesEc: valuesEc,
                    valuesPh: valuesPh,
                    valuesBrightness: valuesBrightness
                }
            };
        }, () => {
                console.log(this.state);
            }
        );
    }

    onRowInputChange(i, event) {

        const targetValue = event.target.value;
        const targetType = event.target.dataset.type;

        switch(targetType) {
            case 'day':
                let valuesDay = [...this.state.formData.valuesDay];
                valuesDay[i] = targetValue;
                // this.setState({ valuesDay });

                this.setState( (prevState, props) => {
                    return {
                        formData: {
                            ...prevState.formData,
                            valuesDay
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            case 'temperature':
                let valuesTemperature = [...this.state.formData.valuesTemperature];
                valuesTemperature[i] = targetValue;
                // this.setState({ valuesTemperature });

                this.setState( (prevState, props) => {
                    return {
                        formData: {
                            ...prevState.formData,
                            valuesTemperature
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            case 'ec':
                let valuesEc = [...this.state.formData.valuesEc];
                valuesEc[i] = targetValue;
                // this.setState({ valuesEc });

                this.setState( (prevState, props) => {
                    return {
                        formData: {
                            ...prevState.formData,
                            valuesEc
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            case 'ph':
                let valuesPh = [...this.state.formData.valuesPh];
                valuesPh[i] = targetValue;
                // this.setState({ valuesPh });

                this.setState( (prevState, props) => {
                    return {
                        formData: {
                            ...prevState.formData,
                            valuesPh
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            case 'brightness':
                let valuesBrightness = [...this.state.formData.valuesBrightness];
                valuesBrightness[i] = targetValue;
                // this.setState({ valuesBrightness });

                this.setState( (prevState, props) => {
                    return {
                        formData: {
                            ...prevState.formData,
                            valuesBrightness
                        }
                    };
                }, () => {
                        // console.log(this.state);
                    }
                );
            break;

            default:
            break;
        }
    }

    render() {

        return(
            <Row className="mb-2">
                <Col>
                    <Form
                        onSubmit={this.onSubmit}
                        // ref="refForm"
                        ref={ (ref) => { this.form = ref; } }
                    >
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Profile Name" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Descriptions</Form.Label>
                            <Form.Control as="textarea" rows="3" style={{ resize:"none" }} placeholder="Profile Descriptions" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Crop Days</Form.Label>
                            <Form.Control as="select">
                                <option value="">
                                    -- Please Select --
                                </option>
                                <option value="30">30</option>
                                <option value="45">45</option>
                                <option value="60">60</option>
                                <option value="90">90</option>
                                <option value="120">120</option>
                            </Form.Control>
                        </Form.Group>
                        <hr />

                        <Form.Group>
                            <Form.Label className="mb-1">
                                <span className="mr-1">Daily Configuration</span>
                            </Form.Label>
                            <Button variant="primary" onClick={this.onAddDailyRow.bind(this)}>
                                <i className="icon icon-plus xs" />
                            </Button>
                        </Form.Group>

                        <Form.Row className="font-weight-bold mb-2">
                            <Col xs={2}>Days</Col>
                            <Col xs={2}>Temperature</Col>
                            <Col xs={2}>EC</Col>
                            <Col xs={2}>pH</Col>
                            <Col xs={2}>Brightness</Col>
                            <Col></Col>
                        </Form.Row>
                        {this.renderDailyRow()}
                    </Form>
                </Col>
            </Row>
        );
    }
}

RackProfileForm.propTypes = {
    afterSubmit: PropTypes.func
};

export default RackProfileForm;