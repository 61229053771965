import React from 'react';
import PropTypes from 'prop-types';

class LoadingIcon extends React.Component {
  render() {
    return (
      <div className="text-center">
          <i className={"icon " + this.props.iconName + " animate-spin"} />
      </div>
    )
  }
}
LoadingIcon.propTypes = {
    iconName: PropTypes.string.isRequired

};
LoadingIcon.defaultProps = {
  iconName: 'icon-spin1'
};

export default LoadingIcon;
