import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import apiHandler from '../libs/ApiBase';
import { Api } from '../configs/Api';

class RackProfileList extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            response: {
                status: null,
                data: null
            },
            profile: []
        };

        this.onAdd = this.onAdd.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }
    componentDidMount() {
        let rack_id = this.props.rack_id;
        this.getRackProfileData(rack_id);
    }

    getRackProfileData(rack_id) {

        apiHandler.get(Api.racks + '/' + rack_id + '/profiles')
        .then((response) => {

            let data = {};

            // console.log(response.data);
            // if( !Object.keys(response.data).length ) {
            //     throw "Empty rack profile data";
            // }
            data = response.data;

            this.setState({
                response: {
                    status: response.status
                },
                profile: data
            }, () => {
                console.log( this.state );
            });

        }).catch((error) => {

            const response = error.response;

            this.setState({
                response: {
                    status: response.status,
                    data: response.data
                },
                profile: []
            }, () => {
                console.error( this.state );
            });

        });
    }

    onAdd(event) {
        /* TODO */
        console.log("onAdd");

        if(typeof this.props.afterAdd === 'function'){
            this.props.afterAdd();
        }
    }

    onEdit(id, event) {
        /* TODO */
        console.log("onEdit " + id);

        if(typeof this.props.afterEdit === 'function'){
            this.props.afterEdit();
        }
    }

    onDelete(id, event) {
        /* TODO */
        console.log("onDelete " + id);

        if(typeof this.props.afterDelete === 'function'){
            this.props.afterDelete();
        }
    }

    render() {

        const cProfile = this.state.profile || [];

        return(
            <React.Fragment>
                <Row className="mb-2">
                    <Col>
                        <Button variant="primary" className="mr-1" onClick={(e)=>{this.onAdd(e)}}>
                            <i className="icon icon-plus xs" />
                        </Button>
                    </Col>
                </Row>
                <Row className="font-weight-bold mb-2">
                    <Col xs={3}>Name</Col>
                    <Col xs={4}>Descriptions</Col>
                </Row>
                {cProfile.map((_profile, _id1) => (
                <Row key={_id1} className="mb-1">
                    <Col xs={3}>{_profile.name}</Col>
                    <Col xs={4}>{_profile.descriptions}</Col>
                    <Col>
                    <Button variant="primary" className="mr-1" onClick={(e)=>{this.onDelete(_profile.id, e)}}>
                        <i className="icon icon-trash xs" />
                    </Button>
                    <Button variant="primary" className="mr-1" onClick={(e)=>{this.onEdit(_profile.id, e)}}>
                        <i className="icon icon-edit xs" />
                    </Button>
                    </Col>
                </Row>
                ))}
            </React.Fragment>
        );
    }
}

RackProfileList.propTypes = {
    rack_id: PropTypes.number,
    afterAdd: PropTypes.func,
    afterEdit: PropTypes.func,
    afterDelete: PropTypes.func
};

export default RackProfileList;