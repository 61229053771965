import React from 'react';
import {
    Container
} from 'react-bootstrap';
import PropTypes from 'prop-types';

class HttpError extends React.Component {

  render() {
    const response = this.props.response;
    return (
        <Container fluid={true} as="section" className="content eooro-content">
            <h2 className="text-center py-5">
                {response.data? (response.data.detail?response.data.detail:response.data) : 'Unknow error'}
            </h2>
        </Container>
    )
  }

}
HttpError.propTypes = {
    response: PropTypes.object.isRequired

};

export default HttpError;