import React from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Jumbotron,
    Breadcrumb,
    Form
} from 'react-bootstrap';

import { Api } from '../configs/Api';
import { apiHandler, Utils } from '../libs';
import { LoadingIcon, CircularStat } from '../components';

import './PlantDetails.scss';
import plant_cover from '../assets/images/plant-cover.jpg'
import leaf_png from '../assets/images/leaf.svg'

class PlantDetailsContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            response: {
                status: null,
                data: null
            },
            plant: {},
            formData: {},
            isFormEditing: false
        };

        this.plantIconMap = {
            Temperature: "icon-temperature",
            Humidity: "icon-humidity",
            CO2: "icon-co2",
            O3: "icon-o3",
            Intensity: "icon-intensity",
            Shade: "icon-shade",
        }

        this.rackIconMap = {
            Temperature: 'icon-temperature',
            EC: 'icon-ec',
            pH: 'icon-ph',
        }

        this.onPlantOpneEdit = this.onPlantOpneEdit.bind(this);
        this.onPlantCloseEdit = this.onPlantCloseEdit.bind(this);
        this.onPlantInputChange = this.onPlantInputChange.bind(this);
        this.onPlantSubmit = this.onPlantSubmit.bind(this);
    }

    componentDidMount() {
        let plant_id = this.props.match.params.plant_id;

        this.getPlantData(plant_id);
        setInterval(() => {
            this.getPlantData(plant_id);
        }, 5000);
    }

    getPlantData(plant_id) {

        /*
        this.setState({
            plant: plant
        }, () => {
            // console.log( this.state );
        });
        */

        apiHandler.get(Api.plants + '/' + plant_id)
        .then((response) => {

            if( !Object.keys(response.data).length ) {
                const errorMessage =  { response:{ status : 0, data : "Empty plant data" } };
                throw errorMessage;
            }

            this.setState({
                response: {
                    status: response.status
                },
                plant: response.data
            }, () => {
                // console.log( this.state );
            });

        }).catch((error) => {

            if( !Object.keys(error).length ) {
                error = { response:{ status : 0, data : "Can't connect server" } };
            }

            this.setState({
                response: {
                    status: error.response.status,
                    data: error.response.data
                }
            }, () => {
                console.error( this.state );
            });

        });
    }

    onPlantOpneEdit(event) {
        this.setState({
            isFormEditing: true,
            formData: {
                id:this.state.plant.id,
                name:this.state.plant.name,
                description: this.state.plant.description
            }
        });
    }

    onPlantCloseEdit(event) {
        this.setState({
            isFormEditing: false
        });
    }

    onPlantInputChange(event) {

        event.preventDefault();
        const { name, value } = event.target;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        }, () => {
                // console.log(this.state);
            }
        );
    }

    onPlantSubmit(event) {

        event.preventDefault();

        const formData = this.state.formData;

        apiHandler.patch(Api.plants + '/' + formData.id, formData)
        .then((response) => {

            if( !Object.keys(response.data).length ) {
                const errorMessage =  { response:{ status : 0, data : "Empty response plants data" } };
                throw errorMessage;
            }

            this.setState({
                isFormEditing: false,
                response: {
                    status: response.status
                },
                plant: response.data
            }, () => {
                // console.log( this.state );
            });

        }).catch((error) => {

            if( !Object.keys(error).length ) {
                error = { response:{ status : 0, data : "Can't connect server" } };
            }

            this.setState({
                response: {
                    status: error.response.status,
                    data: error.response.data
                }
            }, () => {
                console.error( this.state );
            });

        });
    }

    render() {

        const response = this.state.response;

        const cPlant = this.state.plant || {};
        const cPlantAmbient = (cPlant.stat)? cPlant.stat.ambient : [];
        const cRacks = cPlant.racks || [];
        const cPlantFactory = (cPlant.factory) ? cPlant.factory : { id: null, name: null };

        let result = Utils.getHtmlOnloading(response);

        let content = null;
        if(result.status !== 'done') {
            content = result.html;
        } else {
            content = (
                <React.Fragment>
                    <Container fluid={true} as="section" className="top-breadcrumb px-0">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href={"/factory/"+cPlantFactory.id}>
                                {cPlantFactory.name}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{cPlant.name}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Container>

                    <Container fluid={true} as="section" className="content">
                        <Card>
                            <Card.Body>
                                <Row>
                                { !cPlantAmbient.length?
                                    <Col>
                                        <LoadingIcon iconName="icon-spin6" />
                                    </Col>
                                :
                                    <React.Fragment>
                                        {cPlantAmbient.map((plant_ambient, id1) => (
                                        <Col sm={6} md={4} xl key={id1} className="text-center mb-3">
                                            <div className="mx-auto my-auto w-50">
                                                <CircularStat
                                                    percent={100}
                                                    value={Math.floor(plant_ambient.value).toFixed(2)}
                                                    unit={plant_ambient.unit}
                                                    isAlert={plant_ambient.alert}
                                                    classIcon={this.plantIconMap[plant_ambient.name]}
                                                />
                                            </div>
                                        </Col>
                                        ))}
                                    </React.Fragment>
                                }
                                </Row>
                                <Row>
                                    <Col className={ "text-right" }>
                                        <Button variant="primary" href={'/plant/' + cPlant.id + '/settings'}>
                                            Settings
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>

                    <Container fluid={true} as="section" className="content">
                        <Row>
                        { !cRacks.length?
                            <Col>
                                <LoadingIcon iconName="icon-spin6" />
                            </Col>
                        :
                            <React.Fragment>
                                {cRacks.map((_rack, _id1) => (
                                <Col key={_id1} xs={12} className="mb-3">
                                    <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col xl={6} className="mb-3">
                                                    <Card.Title><img src={leaf_png} alt={_rack.name} width="5%"/> {_rack.name}</Card.Title>
                                                    <Card.Subtitle className="mb-4 text-muted">{_rack.description}</Card.Subtitle>
                                                    <div className="text-right d-none d-xl-block">
                                                        <Button variant="primary" href={'/rack/' + _rack.id}>
                                                            More Details
                                                        </Button>
                                                    </div>
                                                </Col>
                                                <Col xl={6}>
                                                    <Row>
                                                        { !_rack.stat?
                                                            <Col>
                                                                <LoadingIcon iconName="icon-spin6" />
                                                            </Col>
                                                        :
                                                            <React.Fragment>
                                                                {_rack.stat.nutrient.map((rack_nutrient, id2) => (
                                                                <Col key={id2} sm={6} md={4} className="text-center mb-3">
                                                                    <div className="mx-auto my-auto w-50">
                                                                        <CircularStat
                                                                            percent={100}
                                                                            value={Math.floor(rack_nutrient.value).toFixed(2)}
                                                                            unit={rack_nutrient.unit}
                                                                            isAlert={rack_nutrient.alert}
                                                                            classIcon={this.rackIconMap[rack_nutrient.name]}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                ))}
                                                            </React.Fragment>
                                                        }
                                                        <Col xs={12}>
                                                            <div className="text-right d-xl-none">
                                                                <Button variant="primary" href={'/rack/' + _rack.id}>
                                                                    More Details
                                                                </Button>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                ))}
                            </React.Fragment>
                        }
                        </Row>
                    </Container>
                </React.Fragment>
            );
        }

        return (
            <main className="page-container plant-container">

                <Container fluid={true} as="section" className="header px-0">
                    <Jumbotron fluid={true} className="jumbotron-image">

                    { (this.state.isFormEditing === false)?
                        <React.Fragment>
                            <div className="d-none d-xl-block text-absolute" onClick={ (e)=>{this.onPlantOpneEdit(e)} }>
                                <figure className="figure">
                                    <h2>{cPlant.name}</h2>
                                    <p>
                                        {cPlant.description}
                                    </p>
                                    <img src={plant_cover} alt={cPlant.name} />
                                </figure>
                            </div>
                            <div className="d-xl-none" onClick={ (e)=>{this.onPlantOpneEdit(e)} }>
                                <figure className="figure">
                                    <img src={plant_cover} alt={cPlant.name} />
                                    <h1>{cPlant.name}</h1>
                                    <p>
                                        {cPlant.description}
                                    </p>
                                </figure>
                            </div>
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <Form onSubmit={this.onPlantSubmit}>
                                <Form.Group>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="name"
                                        onChange={this.onPlantInputChange}
                                        defaultValue={cPlant.name}
                                        placeholder="Name"
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="description"
                                        onChange={this.onPlantInputChange}
                                        defaultValue={cPlant.description}
                                        placeholder="Description"
                                    />
                                </Form.Group>

                                <Form.Group className={ "text-right" }>
                                    <Button variant="primary" type="button" className="mr-1" onClick={ (e)=>{this.onPlantCloseEdit(e)} }>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type="submit">
                                        Save
                                    </Button>
                                </Form.Group>
                            </Form>
                        </React.Fragment>
                    }

                    </Jumbotron>
                </Container>

                {content}

            </main>
        );
    }
}

export default PlantDetailsContainer;
