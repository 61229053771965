import React from 'react';
import { Container } from 'react-bootstrap';

import './ErrorDetails.scss';

class ErrorDetailsContainer extends React.Component {

    render() {
        return (
            <main className="page-container error-container">
                <Container as="h5" className="text-center">
                    Error
                </Container>
            </main>
        )
    }
}

export default ErrorDetailsContainer;